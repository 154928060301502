import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import {AuthService} from './_services/auth-service';
import { ApiClientService } from './_services/swagger';
import {MatDialog} from '@angular/material/dialog';
import { ModalDirective } from 'ngx-bootstrap';
import { CondominiumService } from './_services/condominium.service';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  templateUrl: './app.component.html',
  styleUrls: ['app.component.css']
})
export class AppComponent implements OnInit {

  @ViewChild('debtModal') debtModal: ModalDirective;

  private token: string;
  private condominium: any;
  private currentUser: any;
  private debt: number;

  readonly stateDebt = 2;
  readonly stateBlocked = 3;


  constructor(private router: Router,
    public authService: AuthService,
    public _apiClient: ApiClientService,
    public condominiumService: CondominiumService,
    ) {
      this.token = localStorage.getItem('token');
      this.condominium = JSON.parse(localStorage.getItem('condominium'));
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

      this.condominiumService.condominium.subscribe(condominium => {
        this.condominium = condominium;
        this.checkerStatusCondominium();
      });
    }

  ngOnInit() {

    // se ejecuta cada que cambian de url
    this.router.events.subscribe((evt) => {

      // esto se ejecuta cuando la pagina aun no ha cargado
      if (!(evt instanceof NavigationEnd)) {
        return;
      }

      // ya cargada la pagina se ejecuta esto
      this.verifyAuth(evt.url);
      this.verifyToken();
      window.scrollTo(0, 0);

    });

    // refresca la info de los condominios
    this.refreshCondominiums();

    // Verficia el estatus del condominium cada cierto tiempo
    setInterval(() => this.checkerStatusCondominium(), 1000 * 60 * 30);
    // refresca el status de los condominios cada cierto tiempo
    setInterval(() => this.refreshCondominiums(), 1000 * 60 * 15);


  }

  // obtiene del backend la info de los condominios, por si ha cambiado
  refreshCondominiums() {
    if (this.authService.isLogged()) {
      this.token = localStorage.getItem('token');

      this._apiClient.getConfiguration(this.token).subscribe(data => {

        const condominiums = data.body.data;

        this.condominiumService.setCondominium(condominiums);

      });
    }
  }

  verifyAuth(url) {
    if (url.indexOf('change') === 1) {
      return;
    }

    if (!this.authService.isLogged()) {
      this.router.navigate(['login']);
    }
  }

  // verifica que el token sea del usuario en el backend
  verifyToken() {

    if (this.authService.isLogged()) {
      this.token = localStorage.getItem('token');

      this._apiClient.ping(this.token).subscribe(data => {
        console.log(data.body.message);
      },
        error => {
        if (error.status === 401) {
          localStorage.clear();
          this.router.navigate(['login']);
        }
        console.log(error);
      });
    }
  }

  // checker status condominium, aparece ventana de adeudo
  checkerStatusCondominium() {
    if (this.authService.isLogged()) {

      this.condominium = JSON.parse(localStorage.getItem('condominium'));

      if (Number(this.condominium.idStateCondominium) === this.stateDebt) {
        this.debt = this.condominium.debt;
        this.debtModal.show();
      } else if (Number(this.condominium.idStateCondominium) === this.stateBlocked) {
        this.router.navigate(['/block']);
      }
    }

  }


}
