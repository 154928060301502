import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import {AuthService } from '../../_services/auth-service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ValidatorService } from '../../_services/validator-service';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiClientService } from '../../_services/swagger';


@Component({
  selector: 'app-recover',
  templateUrl: 'mobilerecover.component.html',
    styleUrls: ['mobilerecover.component.css']
})
export class MobileRecoverComponent implements OnInit {

  public frm: FormGroup;
  private errorApi: any;
  public successMessage: string;
  public errorMessage: string;
  private token: string;

  constructor(
    private authService: AuthService,
    public frmBuilder: FormBuilder,
    public _validator: ValidatorService,
    private router: Router,
    private _apiClient: ApiClientService,
    private _route: ActivatedRoute
  ) {
    this.token = this._route.snapshot.paramMap.get('token');
  }

  ngOnInit() {
    this.form(this.frmBuilder);
  }

  form(frmBuilder) {
    /** Construye un formBuilder con sus validaciones para los input en la vista */
    this.frm = frmBuilder.group({
      token: [''],
      // tslint:disable-next-line:max-line-length
      password: ['', Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(150)])],
      // tslint:disable-next-line:max-line-length
      confirm_password: ['', Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(150)])]
    });
  }

  /** Inicia sesión desde la vista */
  changePassword() {
    /** muestra un loading de autenticando */
    this._validator.validateFields(this.frm);
    this.frm.controls['token'].setValue(this.token);
    /** Verifica si el formulario cumple con las validaciones */
    if (this.frm.valid) {
      /** si no hay error en el formulario continua con la autentificacion */
      this._apiClient.mobileChangePassword(this.frm.value).subscribe(
        data => {
          this.successMessage = data.body.message;
          setTimeout(() => this.clearMessages(true), 8000);
        },
        error => {
          this.errorMessage = error.error.message;
          this.errorApi = error.error.data;
          setTimeout(() => this.clearMessages(false), 8000);
        }
      );
    } else {
      /** Muestra alerta de completar información en el formulario */
      console.log('Error', 'Es necesario que ingreses bien tus credenciales.');

    }
  }

  clearMessages(isCorrect) {

    // if (isCorrect) {
    //   this.redirectToHome();
    // }

    this.successMessage = null;
    this.errorMessage = null;
    this.errorApi = null;
  }

  redirectToHome() {
    // redirecciona a inicio de app
    this.router.navigate(['/login']);
  }

}
