export const navItems = [
  {
    title: true,
    name: 'Frakcio'
  },
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'fa fa-table',
    codeAction: 5600
  },
  {
    name: 'Pagos',
    url: '/manualpayments',
    icon: 'fa fa-credit-card-alt',
    codeAction: 300
  },
  {
    name: 'Cargos',
    url: '/pagos',
    icon: 'fa fa-credit-card-alt',
    codeAction: 400
  },
  {
    name: 'Gastos',
    url: '/expense',
    icon: 'fa fa-credit-card',
    codeAction: 500
  },
  {
    name: 'Facturas',
    url: '/invoice',
    icon: 'fa fa-table',
    codeAction: 600
  },
  {
    name: 'Operaciones de pago',
    url: '/cancelpayments',
    icon: 'fa fa-credit-card',
    codeAction: 700
  },
  {
    name: 'Estados de cuenta',
    url: '/accountstatements',
    icon: 'fa fa-credit-card',
    codeAction: 800
  },
  {
    name: 'Presupuestos',
    url: '/estimates',
    icon: 'fa fa-credit-card',
    codeAction: 900
  },
  {
    name: 'Pagos no identificados',
    url: '/unrecognizedpayment',
    icon: 'fa fa-credit-card',
    codeAction: 1000
  },
  {
    name: 'Recargos',
    url: '/recharge',
    icon: 'fa fa-credit-card',
    codeAction: 1100
  },
  {
    name: 'Reportes',
    url: '#',
    icon: 'fa fa-table',
    children: [
      {
        name: 'Reporte de pagos',
        url: '/reportpayment',
        icon: 'fa',
        codeAction: 1300
      },
      {
        name: 'Reporte de adeudos',
        url: '/reportdebtors',
        icon: 'fa',
        codeAction: 1400
      },
      {
        name: 'Reporte de gastos',
        url: '/reportexpense',
        icon: 'fa',
        codeAction: 1700
      },
      {
        name: 'Facturación por día',
        url: '/invoicebyday',
        icon: 'fa',
        codeAction: 1500
      },
      {
        name: 'Facturación por mes',
        url: '/invoicebymonth',
        icon: 'fa',
        codeAction: 1600
      },
      {
        name: 'Polizas contables',
        url: '/reportpolicy',
        icon: 'fa',
        codeAction: 1800
      },
      {
        name: 'Tabla de cobranza',
        url: '/reportcollectiontable',
        icon: 'fa',
        codeAction: 1900
      },
      {
        name: 'Reporte de saldos',
        url: '/excelbalancereport',
        icon: 'fa',
        codeAction: 2000
      },
      {
        name: 'Reporte de presupuesto',
        url: '/reportestimate',
        icon: 'fa',
        codeAction: 2100
      },
      {
        name: 'Reporte de bancos',
        url: '/reportbank',
        icon: 'fa',
        codeAction: 2200
      },
      {
        name: 'Reporte movimientos',
        url: '/reporthousemovements',
        icon: 'fa',
        codeAction: 2300
      },
      {
        name: 'Reporte pago detallado',
        url: '/reportpaymentdetail',
        icon: 'fa',
        codeAction: 2400
      },
      {
        name: 'Reporte propiedades/residentes',
        url: '/reporthouseandresidents',
        icon: 'fa',
        codeAction: 6000
      },
      {
        name: 'Reporte de cargos',
        url: '/reportchargehouse',
        icon: 'fa',
        codeAction: 6100
      },
      {
        name: 'Reporte mixto',
        url: '/reportmix',
        icon: 'fa',
        codeAction: 6200
      },
      {
        name: 'Reporte invitaciones',
        url: '/reportinvitation',
        icon: 'fa',
        codeAction: 6300
      },
      {
        name: 'Reporte estado de liquidación',
        url: '/reportstatesale',
        icon: 'fa',
        codeAction: 6400
      },
      {
        name: 'Reporte de incidencias',
        url: '/reportincidence',
        icon: 'fa',
        codeAction: 6900
      },
    ]
  },
  {
    name: 'Documentos',
    url: '#',
    icon: 'fa fa-table',
    children: [
      {
        name: 'Categoría de documentos',
        url: '/documentcategory',
        icon: 'fa',
        codeAction: 5400
      },
      {
        name: 'Cargar documentos',
        url: '/document',
        icon: 'fa',
        codeAction: 5500
      },
    ]
  },
  {
    name: 'Noticias',
    url: '/noticia',
    icon: 'icon-book-open',
    codeAction: 2500
  },
  {
    name: 'Eventos',
    url: '/evento',
    icon: 'icon-calendar',
    codeAction: 2600
  },
  {
    name: 'Votaciones',
    url: '/proyecto',
    icon: 'icon-puzzle',
    codeAction: 2700
  },
  {
    name: 'Reservaciones',
    url: '/reserve',
    icon: 'fa fa-calendar',
    codeAction: 2800
  },
  {
    name: 'Incidencias',
    url: '/incidencia',
    icon: 'icon-layers',
    codeAction: 2900
  },
  {
    name: 'Emergencias',
    url: '/emergencia',
    icon: 'icon-bell',
    codeAction: 3000
  },
  {
    name: 'Hospedaje',
    url: '#',
    icon: 'icon-home',
    children: [
      {
            name: 'Hospedajes',
            url: '/housing',
            icon: 'fa',
            codeAction: 6700,
      },
      {
        name: 'Check In',
        url: '/housingcheckin',
        icon: 'fa',
        codeAction: 6707,
      },
      {
        name: 'Reporte de hospedajes',
        url: '/housingreport',
        icon: 'fa',
        codeAction: 6710,
      }
    ]
  },
  /*{
    name: 'Reglamento',
    url: '/rule',
    icon: 'fa fa-list'
  },
  */
  {
    name: 'Accessos',
    url: '#',
    icon: 'icon-user',
    children: [
      {
            name: 'Reporte de visitantes',
            url: '/access/visitorreport',
            icon: 'fa',
            codeAction: 3200
      },
      {
        name: 'Reporte de eventos',
        url: '/eventreport',
        icon: 'fa',
        codeAction: 7300
      }
    ]
   },
   {
    name: 'Configuración',
    url: '#',
    icon: 'icon-settings',
    children: [
      {
            name: 'General',
            url: '/configuracion',
            icon: 'fa',
            codeAction: 3200
      },
      {
        name: 'Residentes',
        url: '/residente',
        icon: 'fa',
        codeAction: 3300
      },
      {
        name: 'Propiedades',
        url: '/unidades',
        icon: 'fa',
        codeAction: 3400
      },
      {
        name: 'Listados',
        url: '/listhouse',
        icon: 'fa',
        codeAction: 3500
      },
      {
        name: 'Usuarios Admin',
        url: '/usuarios',
        icon: 'fa',
        codeAction: 3600
      },
      {
        name: 'Cargos manuales',
        url: '/manualcharges',
        icon: 'fa',
        codeAction: 3700
      },
      {
        name: 'Importación de cargos',
        url: '/chargesimportexcel',
        icon: 'fa',
        codeAction: 3800
      },
      {
        name: 'Directorio',
        url: '/directorio',
        icon: 'fa',
        codeAction: 3900
      },
      {
        name: 'Areas comúnes',
        url: '/commonarea',
        icon: 'fa',
        codeAction: 4000
      },
      {
        name: 'Cuentas bancarias',
        url: '/account',
        icon: 'fa',
        codeAction: 4100
      },
      {
        name: 'Extraordinario',
        url: '/extraordinarycharge',
        icon: 'fa',
        codeAction: 4200
      },
      {
        name: 'Conceptos',
        url: '/concept',
        icon: 'fa',
        codeAction: 4300
      },
      {
        name: 'Categorías',
        url: '/conceptcategory',
        icon: 'fa',
        codeAction: 4400
      },
      {
        name: 'Proveedores',
        url: '/provider',
        icon: 'fa',
        codeAction: 4500
      },
      {
        name: 'Vehículos',
        url: '/car',
        icon: 'fa',
        codeAction: 4600
      },
      {
        name: 'Invitaciones',
        url: '/invitation',
        icon: 'fa',
        codeAction: 4700
      },
      {
        name: 'Servicios',
        url: '/service',
        icon: 'fa',
        codeAction: 4800
      },
      {
        name: 'Carga de servicios',
        url: '/servicecharge',
        icon: 'fa',
        codeAction: 4900
      },
      {
        name: 'Historico de servicios',
        url: '/servicechargehouse',
        icon: 'fa',
        codeAction: 5000
      },
      {
        name: 'Movimientos',
        url: '/accountmovement',
        icon: 'fa',
        codeAction: 5100
      },
      {
        name: 'Tarjetas de acceso',
        url: '/accesscard',
        icon: 'fa',
        codeAction: 5200
      },
      {
        name: 'Datos fiscales',
        url: '/fiscaldata',
        icon: 'fa',
        codeAction: 5300
      },
      {
        name: 'Roles App',
        url: '/rolapp',
        icon: 'fa',
        codeAction: 5700
      },
      {
        name: 'Tarifas de servicio',
        url: '/servicetariff',
        icon: 'fa',
        codeAction: 5900
      },
      {
        name: 'Medidores de servicio',
        url: '/servicemeter',
        icon: 'fa',
        codeAction: 6500
      },
      {
        name: 'Roles Admin',
        url: '/rol',
        icon: 'fa',
        codeAction: 5800
      },
      {
        name: 'Tarifas',
        url: '/tariff',
        icon: 'fa',
        codeAction: 6600
      },
      {
        name: 'Liquidaciones',
        url: '/payouts',
        icon: 'fa',
        codeAction: 7400
      },
      {
        name: 'Cargos recurrentes',
        url: '/recurringcharges',
        icon: 'fa',
        codeAction: 7500
      },
      {
        name: 'Mascotas',
        url: '/pet',
        icon: 'fa',
        codeAction: 7000
      },
    ]
  },
  // {
  //   name: 'Residentes',
  //   url: '/residentes',
  //   icon: 'icon-drop'
  // },
  // {
  //   title: true,
  //   name: 'Components'
  // },
  // {
  //   name: 'Base',
  //   url: '/base',
  //   icon: 'icon-puzzle',
  //   children: [
  //     {
  //       name: 'Cards',
  //       url: '/base/cards',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Carousels',
  //       url: '/base/carousels',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Collapses',
  //       url: '/base/collapses',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Forms',
  //       url: '/base/forms',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Pagination',
  //       url: '/base/paginations',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Popovers',
  //       url: '/base/popovers',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Progress',
  //       url: '/base/progress',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Switches',
  //       url: '/base/switches',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Tables',
  //       url: '/base/tables',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Tabs',
  //       url: '/base/tabs',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Tooltips',
  //       url: '/base/tooltips',
  //       icon: 'icon-puzzle'
  //     }
  //   ]
  // },
  // {
  //   name: 'Buttons',
  //   url: '/buttons',
  //   icon: 'icon-cursor',
  //   children: [
  //     {
  //       name: 'Buttons',
  //       url: '/buttons/buttons',
  //       icon: 'icon-cursor'
  //     },
  //     {
  //       name: 'Dropdowns',
  //       url: '/buttons/dropdowns',
  //       icon: 'icon-cursor'
  //     },
  //     {
  //       name: 'Brand Buttons',
  //       url: '/buttons/brand-buttons',
  //       icon: 'icon-cursor'
  //     }
  //   ]
  // },
  // {
  //   name: 'Charts',
  //   url: '/charts',
  //   icon: 'icon-pie-chart'
  // },
  // {
  //   name: 'Icons',
  //   url: '/icons',
  //   icon: 'icon-star',
  //   children: [
  //     {
  //       name: 'CoreUI Icons',
  //       url: '/icons/coreui-icons',
  //       icon: 'icon-star',
  //       badge: {
  //         variant: 'success',
  //         text: 'NEW'
  //       }
  //     },
  //     {
  //       name: 'Flags',
  //       url: '/icons/flags',
  //       icon: 'icon-star'
  //     },
  //     {
  //       name: 'Font Awesome',
  //       url: '/icons/font-awesome',
  //       icon: 'icon-star',
  //       badge: {
  //         variant: 'secondary',
  //         text: '4.7'
  //       }
  //     },
  //     {
  //       name: 'Simple Line Icons',
  //       url: '/icons/simple-line-icons',
  //       icon: 'icon-star'
  //     }
  //   ]
  // },
  // {
  //   name: 'Widgets',
  //   url: '/widgets',
  //   icon: 'icon-calculator',
  //   badge: {
  //     variant: 'info',
  //     text: 'NEW'
  //   }
  // },
  // {
  //   divider: true
  // },
  // {
  //   title: true,
  //   name: 'Extras',
  // },
  // {
  //   name: 'Pages',
  //   url: '/pages',
  //   icon: 'icon-star',
  //   children: [
  //     {
  //       name: 'Login',
  //       url: '/login',
  //       icon: 'icon-star'
  //     },
  //     {
  //       name: 'Register',
  //       url: '/register',
  //       icon: 'icon-star'
  //     },
  //     {
  //       name: 'Error 404',
  //       url: '/404',
  //       icon: 'icon-star'
  //     },
  //     {
  //       name: 'Error 500',
  //       url: '/500',
  //       icon: 'icon-star'
  //     }
  //   ]
  // },
  // {
  //   name: 'Download CoreUI',
  //   url: 'http://coreui.io/angular/',
  //   icon: 'icon-cloud-download',
  //   class: 'mt-auto',
  //   variant: 'success'
  // },
  // {
  //   name: 'Try CoreUI PRO',
  //   url: 'http://coreui.io/pro/angular/',
  //   icon: 'icon-layers',
  //   variant: 'danger'
  // }
];
