import { BrowserModule } from '@angular/platform-browser';
import { Component, NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgxLoadingModule } from 'ngx-loading';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RecoverComponent } from './views/recover/recover.component';
import { MobileRecoverComponent } from './views/mobilerecover/mobilerecover.component';
import { RegisterComponent } from './views/register/register.component';
import { NotificationsModule } from './views/notifications/notifications.module';

// component
// import { AddNewsComponent } from './views/new/add/add-news.component';
// import { EditNewsComponent } from './views/new/edit/edit-news.component';
import {AddEventComponent } from './views/events/add/add-event.component';
import { EditEventComponent } from './views/events/edit/edit-event.component';


const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';
import {AuthService } from './_services/auth-service';
import { ErrorService } from './_services/error.service';
import { ApiClientService } from './_services/swagger';
import { ValidatorService } from './_services/validator-service';

import { HttpModule } from '@angular/http';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { FieldErrorDisplayComponent } from './components/validate-form/field-error-display.component';
import { FieldErrorDisplayModule } from './components/validate-form/field-error-display.module';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

// Alert Component
import { AlertModule } from 'ngx-bootstrap/alert';
import { AlertsComponent } from './views/notifications/alerts.component';

// Modal Component
import { ModalModule } from 'ngx-bootstrap/modal';
import { ModalsComponent } from './views/notifications/modals.component';
import { MainPipeModule } from './pipe/mainpipe.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MessageService } from '@progress/kendo-angular-l10n';
import { KendoMessageService } from './_services/kendo-message.service';
import { ModalMessageComponent } from './components/modal/modal-message.component';

import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { MatDialogModule } from '@angular/material';
import { ErrorInterceptor } from './common/interceptors/errorinterceptor';


@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    HttpModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule.forRoot(),
    ModalModule.forRoot(),
    FieldErrorDisplayModule,
    MainPipeModule,
    NgxLoadingModule.forRoot({}),
    DropDownsModule,
    MatDialogModule,
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RecoverComponent,
    MobileRecoverComponent,
    RegisterComponent,
    // AddNewsComponent,
    // EditNewsComponent,
    AddEventComponent,
    EditEventComponent,
    AlertsComponent,
    ModalsComponent,
    ModalMessageComponent,


    // FieldErrorDisplayComponent,

  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: MessageService,
      useClass: KendoMessageService
    },
  AuthService,
  ApiClientService,
  ValidatorService,
  ErrorService,
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

  ],
  entryComponents: [
    ModalMessageComponent,


  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
