import { Component, OnInit, ElementRef, ViewChild  } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '../../../../../node_modules/@angular/router';
import { ApiClientService } from '../../../_services/swagger';
import { EventsResponse } from '../../../_services/swagger/models';
import { ValidatorService } from '../../../_services/validator-service';
import { Router } from '@angular/router';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'edit-event',
  templateUrl: 'edit-event.component.html'
})
export class EditEventComponent {

  idEvent: number;
  lstEvents: EventsResponse[] = [];
  oEvent: any;
  public frm: FormGroup;
  private token: string;
  
  @ViewChild('fileInput') fileInput: ElementRef;

  constructor(
    private _router: ActivatedRoute,
    private router: Router,
    private _apiClient: ApiClientService,
    public _validator: ValidatorService,
    public frmBuilder: FormBuilder,

  ) { }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {
    this.idEvent = this._router.snapshot.queryParams['idEvent'];
   
    this.token = localStorage.getItem('token');
    this.getEvent(this.idEvent);
    this.form(this.frmBuilder);

  }

  getEvent(idEvent: number) {

        // this._apiClient.events(this.token).subscribe(
        //     data => {
              
        //         this.lstEvents = data.body.data;
        //         this.oEvent = this.lstEvents.filter(x => x.id == this.idEvent)[0];
        //         this.setValues();

        //     },
        //     error => {
        //     },
        //     () => {
        //     });
  }

  form(frmBuilder) {
    /** Construye un formBuilder con sus validaciones para los input en la vista */
    this.frm = frmBuilder.group({
      
      id: ['', Validators.compose([Validators.required])],
      idStatus: ['', Validators.compose([Validators.required])],
      title: ['', Validators.compose([Validators.required])],
      description: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
      startDate: ['', Validators.compose([Validators.required])],
      endDate: ['', Validators.compose([Validators.required])],
      location: ['', Validators.compose([Validators.required])],
      content: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
      image: [''],
    });
  }

  setValues(){
    this.frm.get('id').setValue(this.oEvent.id);
    this.frm.get('idStatus').setValue(this.oEvent.idStatus);
    this.frm.get('title').setValue(this.oEvent.title);
    this.frm.get('description').setValue(this.oEvent.description);
    this.frm.get('startDate').setValue(this.oEvent.startDate.replace(' 00:00:00', ''));
    this.frm.get('endDate').setValue(this.oEvent.endDate.replace(' 00:00:00', ''));
    this.frm.get('location').setValue(this.oEvent.location);
    this.frm.get('content').setValue(this.oEvent.content);
  }

  onSubmit(){
    
    /** muestra un loading de autenticando */
    this._validator.validateFields(this.frm);

    /** Verifica si el formulario cumple con las validaciones */
    if (this.frm.valid) {

      /** si no hay error en el formulario continua con la autentificacion */
      // this._apiClient.updateEvent(this.token, this.frm.value)
      // .subscribe(
      //   data => {
      //     this.router.navigate(['/evento']);
      //   },
      //   error => {
      //     console.log(error)
      //   }
      // );
    } else {
      /** Muestra alerta de completar información en el formulario */
      console.log('Error', 'Es necesario que ingreses bien tus credenciales.');

    }
  }
  onFileChange(event) {
    let reader = new FileReader();
    if(event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.frm.get('image').setValue({
          filename: file.name,
          filetype: file.type,
          value: reader.result.split(',')[1]
        })
      };
    }
  }

  clearFile() {
    this.frm.get('image').setValue(null);
    this.fileInput.nativeElement.value = '';
  }
}
