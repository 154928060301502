import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ApiClientService } from '../../../_services/swagger';

import { ValidatorService } from '../../../_services/validator-service';
import { Router } from '@angular/router';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'add-event',
  templateUrl: 'add-event.component.html'
})
export class AddEventComponent implements OnInit {

  public frm: FormGroup;
  private token: string;

  @ViewChild('fileInput') fileInput: ElementRef;

  constructor(
    public frmBuilder: FormBuilder,
    public _validator: ValidatorService,
    private router: Router,
    private _apiClient: ApiClientService,
  ) { 
    this.token = localStorage.getItem('token');
  }

  ngOnInit(): void {
    

    this.form(this.frmBuilder);
  }

  form(frmBuilder) {
    /** Construye un formBuilder con sus validaciones para los input en la vista */
    this.frm = frmBuilder.group({
      
      title: ['', Validators.compose([Validators.required])],
      description: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
      startDate: ['', Validators.compose([Validators.required])],
      endDate: ['', Validators.compose([Validators.required])],
      location: ['', Validators.compose([Validators.required])],
      content: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
      image: ['', Validators.compose([Validators.required])],

    });
  }


  onSubmit(){
    
    /** muestra un loading de autenticando */
    // this._validator.validateFields(this.frm);

    // /** Verifica si el formulario cumple con las validaciones */
    // if (this.frm.valid) {
    //   /** si no hay error en el formulario continua con la autentificacion */
    //   this._apiClient.createEvent(this.token, this.frm.value)
    //   .subscribe(
    //     data => {
    //       this.router.navigate(['/evento']);
    //     },
    //     error => {
    //       console.log(error)
    //     }
    //   );
    // } else {
    //   /** Muestra alerta de completar información en el formulario */
    //   console.log('Error', 'Es necesario que ingreses bien tus credenciales.');

    // }
  }

   onFileChange(event) {
    let reader = new FileReader();
    if(event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.frm.get('image').setValue({
          filename: file.name,
          filetype: file.type,
          value: reader.result.split(',')[1]
        })
      };
    }
  }

  clearFile() {
    this.frm.get('image').setValue(null);
    this.fileInput.nativeElement.value = '';
  }

}
