import { Component, Input } from '@angular/core';
import { navItems } from '../../_nav';
import { AuthService } from '../../_services/auth-service';
import { Router } from '@angular/router';
import { CondominiumService } from '../../_services/condominium.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {

  public navItems;
  public navItemsDropdownlist;
  public lstNavItemsDropdownlistFilter: any[];
  public condominiumsFilter: any[];
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;
  public currentUser: any;
  public condominium: any;
  public condominiumSelect: string;
  public year: string;

  constructor(private authService: AuthService, private router: Router,
    public condominiumService: CondominiumService) {
    const date = new Date();
    this.year = date.getFullYear().toString();

    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = document.body.classList.contains('sidebar-minimized');
    });

    this.changes.observe(<Element>this.element, {
      attributes: true
    });
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.condominium = JSON.parse(localStorage.getItem('condominium'));
    this.condominiumSelect = this.condominium.name;
    this.navItems = this.getNavItems();
    this.navItemsDropdownlist = this.getNavItemsDropdownList();
    this.lstNavItemsDropdownlistFilter = this.navItemsDropdownlist;
    this.condominiumsFilter = this.currentUser.condominiums;

  }

  onChange(condominium) {

    if (condominium) {
      // ir de nuevo por la información ya que pudo cambiar
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.condominium = this.currentUser.condominiums.find(o => o.name === condominium);
      this.condominiumSelect = condominium;
      // localStorage.setItem('condominium', JSON.stringify(this.condominium));
      // console.log("se cambia");
      // se llama al observador que se ha cambiado el condominio
      this.condominiumService.setCurrentCondominium(this.condominium);
      this.router.navigate(['']);
      this.navItems = this.getNavItems();
      this.navItemsDropdownlist = this.getNavItemsDropdownList();
      this.lstNavItemsDropdownlistFilter = this.navItemsDropdownlist;
    }
  }

  // Genera el menu conforme a los permisos
  getNavItems() {

    // solo aplicamos permisos cuando tenga rol el usuario
    if (this.condominium.idRol) {
      const navItemsCopy = JSON.parse(JSON.stringify(navItems));

      let items = navItemsCopy.filter(e => this.condominium.actions.includes(e.codeAction) ||
        e.url === '#');

      // recorrer elementos con hijos
      for (const item of items.filter(e => e.url === '#')) {
        const childrenAllowed = item.children.filter(e => this.condominium.actions.includes(e.codeAction));

        item.children = childrenAllowed;
        if (childrenAllowed.length === 0) {
          items = items.filter(e => e !== item);
        }
      }

      return items;
    }

    // si es null su permiso, se retorna el objeto original
    return navItems;
  }

  // Genera el menu del dropdownlist una vez ya esta generado el menu con los elementos permitidos
  getNavItemsDropdownList() {

    const items = [];
    for (const item of this.navItems) {
      if (item.children) {
        for (const child of item.children) {
          items.push(child);
        }
      } else {
        items.push(item);
      }
    }

    if (items.length > 0) {
      items.shift();
    }

    return items;

  }

  logout() {
    this.authService.logout();
    this.router.navigate(['']);
  }

  handleFilter(value) {
    this.lstNavItemsDropdownlistFilter = this.navItemsDropdownlist.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  handleFilterCondominium(value) {
    this.condominiumsFilter = this.currentUser.condominiums.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  changeDropdownList(url: string) {
    this.router.navigate([url]);
  }



}
