import {Component, OnInit, TemplateRef} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-component',
  templateUrl: 'modal-message.component.html'
})
export class ModalMessageComponent implements OnInit {
  message: string;

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit() {

  }
}

