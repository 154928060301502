import {Injectable} from '@angular/core';
import { Condominium, ConfigurationResponse } from './swagger/models';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CondominiumService {

  private readonly sessionCurrentUser = 'currentUser';
  private readonly sessionCondominium = 'condominium';

  private currentUser: any;
  private currentCondominium: any;

  private condominiumSubject: BehaviorSubject<Condominium>;
  public condominium: Observable<Condominium>;

  public get condominiumData(): Condominium {
    return this.condominiumSubject.value;
  }

  constructor() {
    this.condominiumSubject =
      new BehaviorSubject<Condominium>(JSON.parse(localStorage.getItem(this.sessionCondominium)));
    this.condominium = this.condominiumSubject.asObservable();
  }


  setCondominium(condominiums: Condominium[]) {

    this.currentUser = JSON.parse(localStorage.getItem(this.sessionCurrentUser));
    // obtenemos el guardado ya que pudo haber cambiado
    // const currentCondominiumStorage = JSON.parse(localStorage.getItem(this.sessionCondominium));

    this.currentUser.condominiums = condominiums;
    this.currentCondominium = this.currentUser.condominiums.find(o => o.id === this.condominiumData.id);

    localStorage.setItem(this.sessionCurrentUser, JSON.stringify(this.currentUser));
    localStorage.setItem(this.sessionCondominium, JSON.stringify(this.currentCondominium));


    this.condominiumSubject.next(this.currentCondominium);
  }

  setCurrentCondominium(condominium: Condominium) {
    console.log(condominium);
    localStorage.setItem(this.sessionCondominium, JSON.stringify(condominium));
    this.condominiumSubject.next(condominium);
  }

}
